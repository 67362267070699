<template>
  <BaseAlertTemplate
    :detailedData="detailedData"
    :settingsData="settingsData"
    :algoStrategyData="algoStrategyData"
    :alert="alert"
  >
  </BaseAlertTemplate>
</template>

<script>
import BaseAlertTemplate from './Base/BaseAlertTemplate.vue'

export default {
  name: 'PerfPerformanceIsOffTrack',
  props: ['alert'],
  components: {
    BaseAlertTemplate
  },
  data: function () {
    return {
      detailedData: [
        {
          label: 'Alert level',
          value: (alert) => {
            return 'The IO'
          },
          endLabel: '',
          roundValue: false,
          isPerc: false,
          colorize: false,
          isDate: false
        },
        {
          label: 'KPI yesterday',
          value: (alert) => {
            if (alert.data.KPI_day_1) {
              return this.$commonUtils.htmlEntities(alert.data.KPI_day_1)
            }
            return 'NC'
          },
          endLabel: '',
          roundValue: false,
          isPerc: false,
          colorize: false,
          isDate: false
        },
        {
          label: '"Average KPI for the billing period',
          value: (alert) => {
            if (alert.data.KPI_period) {
              return this.$commonUtils.htmlEntities(alert.data.KPI_period)
            }
            return 'NC'
          },
          endLabel: '',
          roundValue: false,
          isPerc: false,
          colorize: false,
          isDate: false
        },
        {
          label: 'Estimated KPI for the billing period',
          value: (alert) => {
            if (alert.data.KPI) {
              return this.$commonUtils.htmlEntities(alert.data.KPI)
            }
            return 'NC'
          },
          endLabel: '',
          roundValue: false,
          isPerc: false,
          colorize: false,
          isDate: false
        }
      ],
      settingsData: [],
      algoStrategyData: [
        { label: 'Perf_first', value: 'data.IO_is_perf_first', endLabel: '', roundValue: false, isPerc: false, colorize: false }
      ]
    }
  },
  created: function () {

  },
  mounted: function () {

  },
  methods: {

  },
  computed: {

  },
  watch: {

  }
}
</script>

<style>
</style>
